//
// Select 2
//

.select2-container {
    .select2-selection--single {
        background-color: var(--#{$prefix}secondary-bg);
        border: 1px solid $input-border-color;
        height: 38px;

        &:focus {
            outline: none;
        }

        .select2-selection__rendered {
            line-height: 36px;
            padding-left: $input-padding-x;
            color: var(--#{$prefix}secondary-color);
        }


        .select2-selection__arrow {
            height: 34px;
            width: 34px;
            right: 3px;

            b {
                border-color: var(--#{$prefix}gray-500) transparent transparent transparent;
                border-width: 6px 6px 0 6px;
            }
        }

        .select2-selection__placeholder {
            color: var(--#{$prefix}body-color);
        }
    }
}

[dir="rtl"] {
    .select2-selection__rendered {
        text-align: end;
    }
}


.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent var(--#{$prefix}gray-500) transparent !important;
                border-width: 0 6px 6px 6px !important;
            }
        }
    }
}

.select2-container--default {
    .select2-search--dropdown {
        padding: 10px;
        background-color: var(--#{$prefix}secondary-bg);

        .select2-search__field {
            border: 1px solid var(--#{$prefix}border-color-translucent);
            background-color: var(--#{$prefix}secondary-bg);
            color: var(--#{$prefix}secondary-color);
            outline: none;
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: $primary;
    }

    .select2-results__option[aria-selected="true"] {
        background-color: var(--#{$prefix}light);
        color: var(--#{$prefix}emphasis-color);

        &:hover {
            background-color: $primary;
            color: $white;
        }
    }

    .select2-selection--multiple {
        .select2-selection__choice__remove {
            border-right: none;
            color: var(--#{$prefix}emphasis-color);
        }
    }
}

.select2-results__option {
    padding: 6px 12px;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-left: $input-padding-x;
}

.select2-dropdown {
    border: 1px solid var(--#{$prefix}border-color);
    background-color: var(--#{$prefix}secondary-bg);
    box-shadow: $box-shadow;
}

.select2-search {
    input {
        border: 1px solid var(--#{$prefix}border-color);
    }
}

.select2-container {
    .select2-selection--multiple {
        min-height: 38px;
        background-color: var(--#{$prefix}secondary-bg);
        border: 1px solid var(--#{$prefix}border-color-translucent) !important;
        padding: 2px $input-padding-x;

        .select2-selection__rendered {
            padding: 2px $input-padding-x;
        }

        .select2-search__field {
            border: 0;
            color: var(--#{$prefix}emphasis-color);
            margin: 0;
            margin-top: 7px;

            &::placeholder {
                color: var(--#{$prefix}secondary-color);
            }
        }

        .select2-selection__choice {
            background-color: var(--#{$prefix}tertiary-bg);
            border: 1px solid var(--#{$prefix}border-color);
            border-radius: 1px;
            padding: 0 7px;
        }

        .select2-selection__rendered {
            padding: 0;
        }
    }
}

.select2-container--default {
    &.select2-container--focus {
        .select2-selection--multiple {
            border-color: var(--#{$prefix}border-color-translucent);
        }
    }

    .select2-results__group {
        font-weight: $font-weight-semibold;
    }

    .select2-selection--multiple {
        .select2-selection__choice__display {
            padding-left: 16px;
        }
    }
}

// ajax select

.select2-result-repository__avatar {
    float: left;
    width: 60px;
    margin-right: 10px;

    img {
        width: 100%;
        height: auto;
        border-radius: 2px;
    }
}

.select2-result-repository__statistics {
    margin-top: 7px;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
    display: inline-block;
    font-size: 11px;
    margin-right: 1em;
    color: var(--#{$prefix}secondary-color);

    .fa {
        margin-right: 4px;

        &.fa-flash {
            &::before {
                content: "\f0e7";
                font-family: "Font Awesome 5 Free";
            }
        }
    }
}

.select2-results__option--highlighted {

    .select2-result-repository__forks,
    .select2-result-repository__stargazers,
    .select2-result-repository__watchers {
        color: rgba($white, 0.8);
    }
}

.select2-result-repository__meta {
    overflow: hidden;
}

// templating-select

.img-flag {
    margin-right: 7px;
    height: 15px;
    width: 18px;
}


.select2-container--default .select2-results__option--selected {
    background-color: var(--#{$prefix}tertiary-bg);
}