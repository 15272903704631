//
// _jobs.scss
//

.jobs-categories {
    a {
        color: var(--#{$prefix}body-color); 
        transition: all 0.5s ease;
        &:hover  {
            color: $primary;
        }
    }
}